<template>
  <div class="serviceGuide">
    <div class="title">服务导向</div>
    <div class="subtitle">将为您制定的五大引导方向，带你领略中医奥妙！</div>
    <div class="clearfix">
      <div>
        <img src="~assets/img/juhezy/ico25.png" alt="" />
        <p>寻中医</p>
        <p>离您最近的好中医 <br> 按流派师承寻中医</p>
      </div>
      <div>
        <img src="~assets/img/juhezy/ico26.png" alt="" />
        <p>约中医</p>
        <p>了解医师知识体系 <br> 看到医师真实案例 <br> 直接预约医师挂号</p>
      </div>
      <div>
        <img src="~assets/img/juhezy/ico4.png" alt="" />
        <p>查中医</p>
        <p>特定病种使用知识 <br> 在线答疑智能AI</p>
      </div>
      <div>
        <img src="~assets/img/juhezy/icon23.png" alt="" />
        <p>听中医</p>
        <p>百家中医 <br> 专辑视听</p>
      </div>
      <div>
        <img src="~assets/img/juhezy/ico24.png" alt="" />
        <p>用中医</p>
        <p>各家中医 <br> 辨机论治</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServieGuide",
};
</script>

<style scoped>
.serviceGuide {
  margin: 50px auto;
  /* width: 1220px; */
}

.title {
  font-size: 30px;
  color: #000;
  line-height: 50px;
}

.subtitle {
  font-size: 24px;
  line-height: 50px;
}

.clearfix {
  margin: 100px 0 150px;
  display: flex;
  justify-content: center;
}

.clearfix > div {
  width: 224px;
  height: 320px;
  text-align: center;
  border: 1px solid #6ec4ff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 28px;
  margin: 0 13px;
}

.clearfix > div p:nth-of-type(1) {
  font-size: 28px;
  margin-top: 25px;
}

.clearfix > div p:nth-of-type(2) {
  width: 160px;
  font-size: 18px;
  line-height: 32px;
  margin: 25px auto 0;
}
</style>
