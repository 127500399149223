<template>
  <div class="juhezy">
    <service-guide />
    <study />
    <quick-poll />
  </div>
</template>

<script>
import ServiceGuide from './childrenCpn/ServiceGuide';
import Study from './childrenCpn/Study';
import QuickPoll from './childrenCpn/QuickPoll';

export default {
  name: "Juhezy",
  components: {
    ServiceGuide,
    Study,
    QuickPoll
  }
};
</script>

<style scoped>
.juhezy {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
