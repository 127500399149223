<template>
  <div class="quickPoll">
    <div class="title">中医知识点速查库</div>
    <div class="subtitle">每个库的内容为您精心挑选，用心整理。 海量知识点，按照理法方药证病案穴等归类整理</div>
    <div class="qpcon">
      <div class="line">
        <div>
          <img src="~assets/img/juhezy/sico.png" alt="">
          <div>
            <p>. . . . .</p>
            <p>经方治感冒简单有效</p>
            <p>感冒是一种常见疾病</p>
            <h5><img src="~assets/img/juhezy/stit.png" alt=""></h5>
          </div>
        </div>
        <div>
          <img src="~assets/img/juhezy/sico2.png" alt="">
          <div>
            <p>. . . . .</p>
            <p>土方法老方法却非常简单有效</p>
            <p>但老一辈的生活经验中的</p>
            <p>感冒是一种常见疾病</p>
            <h5><img src="~assets/img/juhezy/stit2.png" alt=""></h5>
          </div>
        </div>
        <div>
          <img src="~assets/img/juhezy/sico3.png" alt="">
          <div>
            <p>. . . . .</p>
            <p>的安全有效的方剂</p>
            <p>经过千百年的临床实践总结</p>
            <p>中成药是历代中医药学家</p>
            <h5><img src="~assets/img/juhezy/stit3.png" alt=""></h5>
          </div>
        </div>
      </div>
      <div class="line">
        <div>
          <img src="~assets/img/juhezy/sico4.png" alt="">
          <div>
            <p>五术兼备之旷世奇人 . . . .</p>
            <p>被喻为当代少见的命相卜山医</p>
            <p>并精通其它命相卜山</p>
            <p>倪公海厦医师研究中医达30年</p>
            <h5><img src="~assets/img/juhezy/stit4.png" alt=""></h5>
          </div>
        </div>
        <div>
          <img src="~assets/img/juhezy/sico5.png" alt="">
          <div>
            <p>. . . . .</p>
            <p>某种药物在穴位上</p>
            <p>灸法，是利用某种易燃材料和</p>
            <p>法是针灸的重要组成部分</p>
            <h5><img src="~assets/img/juhezy/stit5.png" alt=""></h5>
          </div>
        </div>
        <div>
          <img src="~assets/img/juhezy/sico6.png" alt="">
          <div>
            <p>. . . . .</p>
            <p>著书立说泽及后人</p>
            <p>尊奉黄帝张仲景等人为 "四圣"</p>
            <p>清代乾隆皇帝的御医黄元御</p>
            <h5><img src="~assets/img/juhezy/stit6.png" alt=""></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "QuickPoll"
  }
</script>

<style scoped>
.quickPoll {
  margin: 50px auto;
  width: 1220px;
}

.title {
  font-size: 30px;
  color: #000;
  line-height: 50px;
}

.subtitle {
  font-size: 24px;
  line-height: 50px;
}

.qpcon {
  margin: 60px 0;
}

.line {
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
}

.line > div div {
  display: flex;
  justify-content: flex-end;
  margin: 20px 15px;
}

.line > div div p {
  /* float: right; */
  width: 10px;
  margin: 0 10px;
  font-size: 14px;
}

.line > div div h5 {
  /* float: right; */
  margin-left: 20px;
}
</style>
